//overwrite
body{    
    font-family: $mfont;    
    line-height: 1.5;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    &.fade-in{
        opacity: 1;
        transition:opacity  1.5s ease;
        visibility: visible;
    }
    &.modal-open{
        overflow: hidden;
       
        .overlay,.overbg{
            opacity: 1;            
        }
        @media (min-width:641px){
            padding-right: 17px;
        }
    }
}
html.modal-open{
    overflow: hidden;
}
body{    
    &.modal_open main{
        height: calc(100vh - 40px);
        overflow: hidden;
    }
}
main{ 
    @include media-breakpoint-down(xs){
        // .container,.col-12{
        //     padding-left: 20px;
        //     padding-right: 20px;
        // }
        // .row{
        //     margin-right: -20px;
        //     margin-left: -20px;
        // }
    }
    @include media-breakpoint-down(sm){        
       
    } 
    @include media-breakpoint-up(md){  
       
        
        .container{
            max-width:100%;
        }
    }
}
.overlay,.overbg{   
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:none;
    z-index: 1010;
    overflow-y: scroll;
    opacity: 0;
    transition: opacity 0.5s linear;
    @media (max-width:640px){
        overflow-y: hidden;
    }
}
.overbg{
    z-index: 1012; background-color:rgba(black, 0.8);
}
.modal-content{
    // max-width: 767px;
    // max-height: 600px;
    // width:90%;
    // height: 95%;
    // padding:40px;
    // position: fixed;
    // top:50%;
    // left:50%;
    // transform: translate(-50%, -50%);
    // margin-left: -7px;    
    // z-index: 999;
    // display: none;
    // @media (max-width:640px){
    //     padding: 50px 2.5%;
    //     margin-left: 0px; 
    // }
    // .modalBox{
    //     width: 100%;
    //     height:100%;
    //     text-align:left;
    //     -webkit-overflow-scrolling:touch;
    //     background-color: #fff;        
    //     padding: 25px 20px;
    //     @include border(10px);
    //     @include font-color(pxToEm(12),#666);
    //     @media (max-width:640px){
    //         padding:20px 10px 20px 15px;
    //     }
    // }
    // #telbox{
    //     text-align: center;
    //     display: none;
    //     align-items: center;
    //     justify-content: center;
    //     height: 100%;
    //     color:black;
    //     a[href^="tel:"]{
    //         max-width:450px;
    //         width: 80%;            
    //         color:white;
    //         background-color: $red;
    //         display: block;
    //         margin:35px auto 0;
    //         padding: 15px 0;
    //     }
    //     > div{
    //         flex:0 0 100%;
    //     }
    //     .txt{
    //         font-size: pxToEm(20);
    //         margin:10px 0 0;
    //     }
    // }
    
    // &.telbox{
    //     height: 400px;
    //     #telbox{
    //         display: flex;
    //     }
    // }
    // &.agreebox .text-justify{
    //     display: block;
    // }
    // .text-justify{
    //     display: none;
    //     overflow-y:auto; 
    //     width: 100%;
    //     height:100%;
    //     padding-right: 10px;
    //     line-height: 1.5;
    //     text-align: initial;
    //     @media (max-width:640px){
    //         padding-right: 5px;
    //     }
    // }
    padding:40px;
    position: fixed;
    max-width: 767px;
    max-height: 600px;
    width:90%;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    margin-left: -7px;    
    z-index: 999;
    display: none;
    &.min-vh{
        height: 95%;
    }
    @media (max-width:640px){
        padding: 50px 2.5%;
        margin-left: 0px; 
    }
    .modalBox{
        width: 100%;
        height:100%;
        text-align:left;
        -webkit-overflow-scrolling:touch;
        background-color: #fff;        
        padding: 25px 20px;
        @include border(10px);
        font-size: pxToEm(12);
        @include font-color(#666);
        @media (max-width:640px){
            padding:20px 10px 20px 15px;
        }
    }
    .mflex > div{
        text-align: center;
        flex:0 0 100%;
        color:black;
        @include font-size(pxToEm(16),pxToEm(20));
        a[href^="tel:"],a{
            max-width:450px;
            width: 80%;            
            color:white;
            background-color: $red;
            display: block;
            margin:35px auto 0;
            padding: 15px 0;            
            @include font-size(pxToEm(16),pxToEm(18)); 
        }
        .txt{
            @include font-size(pxToEm(20),pxToEm(24));
            margin:10px 0 0;
        }
    }
    .svg{
        fill:$red;
        @include media-breakpoint-down(md){
            width: 32px;
        }
        @include media-breakpoint-up(md){
            width: 50px;
        }
    }
    .mflex{
        display: none;
        align-items: center;
        justify-content: center;
    }
    .mflex.icon{
        height: 320px;
        display: flex;
    }
    .privacy.text-justify{
        display: block;
    }
    .text-justify{
        display: none;
        overflow-y:auto; 
        width: 100%;
        height:100%;
        padding-right: 10px;
        line-height: 1.5;
        text-align: initial;
        @media (max-width:640px){
            padding-right: 5px;
        }
    }
    .close{
        cursor: pointer;
        // border:2px solid #909399;
        @include border();
        z-index: 99993;
        position: absolute;
        display: block;
        width:40px;
        height: 40px;
        top:0px;
        right:0px;
        &:before, &:after {
            position: absolute;
            left: 18px;
            top:4px;
            content: ' ';
            height: 30px;
            width: 2px;
            background-color: #909399;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        @media(max-width: 640px){
            right:2.5%;
        }
    }
    //內容樣式
    h4{
        text-align: center;
        font-size: pxToEm(32);
        color:#303133;
        margin-bottom: 36px;
    }
    h6{
        font-size: pxToEm(24);
        @include font-color($red,700);
        margin-bottom: 12px;
    }
}
.revealOnScroll{
    opacity: 0;
    transform: translateY(60px);
    transition: opacity 1s ,transform 1s;
}
.revealOnScroll.animated{
    opacity: 1;
    transform: translateY(0px);
}
